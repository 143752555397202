import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import styles from './MobileGameBoardView.module.css';
import {
    ALL_LOTTERIES_BETOPTIONS,
    checkExpiredUserToken,
    FIVE_0F_NINETY,
    generateRandomIntegerInRange,
    getBetOptionFromStorage,
    getUserToken,
    GHANA_GAME,
    INDOOR_GAME,
    LEGENDARY_LOTTO,
    LOTTO,
    safeJSONParse,
} from 'utils';
import ErrorDiv from 'components/shared/ErrorDiv';
import {
    handleSetGameBetOption,
    handleUpdateRandomBetSlips,
    SESSION_STORAGE_CATEGORY,
} from 'redux/actions/lotteryActions';
import { useRouter } from 'next/router';

const MobileRandomSelectionForm = ({
    gameData,
    resultOptionSelect,
    selectedBooster,
    betOptionSelect,
}) => {
    const router = useRouter();

    let lotteryCategory = '';
    if (typeof window === 'object') {
        lotteryCategory = sessionStorage.getItem(SESSION_STORAGE_CATEGORY);
    }
    const dispatch = useDispatch();
    const { gameBetOption, gameResultOption, potentialWinningData } = useSelector(
        (state) => state.lotteryData,
    );
    const { userData, walletInformation } = useSelector((state) => state.userData);

    const formik = useFormik({
        initialValues: {
            linesCount: '',
            amount: '',
            maxValue: '',
            maxAValue: '',
            maxBValue: '',
        },

        onSubmit: async (values, helpers) => {
            try {
                if (userData?.isAgent) {
                    if (!walletInformation?.hasMinimumDailyWalletBalance) {
                        toast.error(
                            "Sorry, You haven't Reached your daily Wallet Limit, Please Fund Your Account to Continue",
                            { duration: 5000 },
                        );
                        return router.push('/deposit');
                    }
                }
                checkExpiredUserToken();
                const token = getUserToken();
                // console.log('first', potentialWinningData);
                let allBetOptions;
                if (typeof window === 'object') {
                    let items = sessionStorage.getItem(ALL_LOTTERIES_BETOPTIONS);
                    if (items) {
                        allBetOptions = JSON.parse(items);
                    }
                }

                if (potentialWinningData?.totalSlips >= 200) {
                    helpers.setSubmitting(false);

                    return toast.error('Maximm Number of BetSlips of 200 have been reached');
                }

                let selectedOption =
                    gameBetOption ||
                    allBetOptions[router?.query?.lotteryCategory][router?.query?.betType] ||
                    getBetOptionFromStorage();
                if (!selectedOption) {
                    dispatch(handleSetGameBetOption(betOptionSelect));
                }
                helpers.setSubmitting(true);
                let count = 0;
                let count1 = 0;
                let count2 = 0;
                let selections = '';
                let selections1 = '';
                let selections2 = '';

                let ballArray = [];
                let array1 = [];
                let array2 = [];
                let payloadList = [];
                let selectionList = [];
                let selectionList1 = [];
                let selectionList2 = [];
                let num, num1, num2;
                let max, maxA, maxB;

                if (values?.maxValue) {
                    if (values.maxValue < selectedOption?.min) {
                        return toast.error(
                            `Selection must be between ${selectedOption?.min} and ${selectedOption?.max}`,
                        );
                    }
                    max = Number(values?.maxValue);
                } else {
                    max = selectedOption?.max;
                }

                if (selectedOption?.isSetAB) {
                    if (values?.maxAValue) {
                        if (values.maxAValue < selectedOption?.minA) {
                            return toast.error(
                                `Set A selections must be between ${selectedOption?.minA} and ${selectedOption?.maxA}`,
                            );
                        }
                        maxA = Number(values?.maxAValue);
                    } else {
                        maxA = selectedOption?.maxA;
                    }
                    if (values?.maxBValue) {
                        if (values.maxBValue < selectedOption?.minB) {
                            return toast.error(
                                `Set B selection must be between ${selectedOption?.minB} and ${selectedOption?.maxB}`,
                            );
                        }
                        maxB = Number(values?.maxBValue);
                    } else {
                        maxB = selectedOption?.maxB;
                    }
                }

                for (let index = 0; index < values.linesCount; index++) {
                    selectedBooster && resultOptionSelect
                        ? payloadList.push({
                              betType: selectedOption?.value,
                              booster: selectedBooster,
                              amount: values.amount,
                              selections: '',
                              resultType: resultOptionSelect,
                              overUnder: null,
                          })
                        : (lotteryCategory === LOTTO ||
                              lotteryCategory === FIVE_0F_NINETY ||
                              lotteryCategory === INDOOR_GAME ||
                              lotteryCategory === GHANA_GAME ||
                              lotteryCategory === LEGENDARY_LOTTO) &&
                          resultOptionSelect === ''
                        ? payloadList.push({
                              betType: selectedOption?.value,
                              booster: selectedBooster,
                              amount: values.amount,
                              selections: '',
                              resultType: 'winning',
                              overUnder: null,
                          })
                        : resultOptionSelect
                        ? payloadList.push({
                              betType: selectedOption?.value,
                              booster: selectedBooster,
                              amount: values.amount,
                              selections: '',
                              resultType: resultOptionSelect,
                              overUnder: null,
                          })
                        : selectedBooster
                        ? payloadList.push({
                              betType: selectedOption?.value,
                              booster: selectedBooster,
                              amount: values.amount,
                              selections: '',
                              resultType: resultOptionSelect,
                              overUnder: null,
                          })
                        : payloadList.push({
                              betType: selectedOption?.value,
                              amount: values.amount,
                              selections: '',
                              resultType: resultOptionSelect,
                              overUnder: null,
                          });
                }
                // console.log('push oe', payloadList);

                if (selectedOption?.isSetAB) {
                    while (count1 <= values?.linesCount + 2 && count2 <= values?.linesCount + 2) {
                        num1 = generateRandomIntegerInRange(1, gameData?.Lottery?.gameCount);
                        num2 = generateRandomIntegerInRange(1, gameData?.Lottery?.gameCount);

                        if (array1.indexOf(num1) === -1) {
                            array1.push(num1);

                            if (array1?.length === maxA) {
                                array1.forEach((elem, index) => {
                                    selections1 +=
                                        index < array1?.length - 1 ? `${elem}-` : `${elem}`;
                                });

                                //Cleaning up
                                selectionList1.push(selections1);
                                array1 = [];
                                selections1 = '';
                                count1 += 1;
                            }
                        }
                        if (array2.indexOf(num2) === -1) {
                            array2.push(num2);

                            if (array2?.length === maxB) {
                                array2.forEach((elem, index) => {
                                    selections2 +=
                                        index < array2?.length - 1 ? `${elem}-` : `${elem}`;
                                });

                                //Cleaning up
                                selectionList2.push(selections2);
                                array2 = [];
                                selections2 = '';
                                count2 += 1;
                            }
                        }
                    }
                } else {
                    while (count < values?.linesCount) {
                        num = generateRandomIntegerInRange(1, gameData?.Lottery?.gameCount);
                        if (ballArray.indexOf(num) === -1) {
                            ballArray.push(num);

                            if (ballArray?.length === max) {
                                ballArray.forEach((elem, index) => {
                                    selections +=
                                        index < ballArray?.length - 1 ? `${elem}-` : `${elem}`;
                                });

                                //Cleaning up
                                selectionList.push(selections);
                                ballArray = [];
                                selections = '';
                                count += 1;
                            }
                        }
                    }
                }

                if (selectedOption?.isSetAB) {
                    payloadList.map(
                        (item, idx) =>
                            (item.selections = `${selectionList1[idx]}/${selectionList2[idx]}`),
                    );
                } else {
                    payloadList.map((item, idx) => (item.selections = selectionList[idx]));
                }
                // console.log('payloadlist', payloadList);
                // console.log('selectionlist1', selectionList1);
                // console.log('selectionlist2', selectionList2);

                await dispatch(handleUpdateRandomBetSlips(payloadList, gameData?.lotteryId));
                helpers.setSubmitting(false);
            } catch (error) {
                // console.log('eeeeeeeeeeee', error?.response);
                helpers.setSubmitting(false);
                if (error?.response?.data?.responsemessage?.msg) {
                    return toast.error(error?.response?.data?.responsemessage?.msg);
                }
                if (error?.response?.data?.responsemessage) {
                    return toast.error(error?.response?.data?.responsemessage);
                }
                toast.error(error.message);
            }
        },

        validationSchema: yup.object().shape({
            linesCount: yup.number().positive().max(50).required().label('Lines count'),
            amount: yup.number().positive().required().label('Amount'),
            maxValue: yup.number().positive().min(2).max(20).label('Number of Selection'),
            maxAValue: yup.number().positive().min(2).max(20).label('Number of Selection'),
            maxBValue: yup.number().positive().min(2).max(20).label('Number of Selection'),
        }),
    });

    return (
        <Form fluid autoComplete="off" onSubmit={formik.handleSubmit}>
            <input autoComplete="false" name="hidden" type="text" style={{ display: 'none' }} />
            <div className={styles.randomSelectionCont}>
                <Form.Group controlId="linesCount">
                    <Form.ControlLabel>Number of Lines:</Form.ControlLabel>
                    <Form.Control
                        autoComplete="off"
                        name="linesCount"
                        type="number"
                        max="50"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                            formik.setFieldValue('linesCount', e);
                        }}
                    />
                    {formik.errors.linesCount ? (
                        <ErrorDiv msg="Number of lines is Requried" />
                    ) : null}
                </Form.Group>
                <Form.Group controlId="amount">
                    <Form.ControlLabel>Amount to stake per line:</Form.ControlLabel>
                    <Form.Control
                        autoComplete="off"
                        name="amount"
                        type="number"
                        step={5}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                            formik.setFieldValue('amount', e);
                        }}
                    />
                    {formik.errors.amount ? <ErrorDiv msg="Amount is Requried" /> : null}
                </Form.Group>
                {gameBetOption?.value?.includes('perm') && (
                    <Form.Group controlId="maxValue">
                        <Form.ControlLabel>Number of selection:</Form.ControlLabel>
                        <Form.Control
                            autoComplete="off"
                            name="maxValue"
                            type="number"
                            onBlur={formik.handleBlur}
                            onChange={(e) => {
                                formik.setFieldValue('maxValue', e);
                            }}
                        />
                        {formik.errors.maxValue ? <ErrorDiv msg={formik.errors.maxValue} /> : null}
                    </Form.Group>
                )}

                {gameBetOption?.isSetAB && (
                    <>
                        <Form.Group controlId="maxAValue">
                            <Form.ControlLabel>Number of Set A selection:</Form.ControlLabel>
                            <Form.Control
                                autoComplete="off"
                                name="maxAValue"
                                type="number"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('maxAValue', e);
                                }}
                            />
                            {formik.errors.maxAValue ? (
                                <ErrorDiv msg={formik.errors.maxAValue} />
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="maxBValue">
                            <Form.ControlLabel>Number of Set B selection:</Form.ControlLabel>
                            <Form.Control
                                autoComplete="off"
                                name="maxBValue"
                                type="number"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('maxBValue', e);
                                }}
                            />
                            {formik.errors.maxBValue ? (
                                <ErrorDiv msg={formik.errors.maxBValue} />
                            ) : null}
                        </Form.Group>
                    </>
                )}
            </div>

            <Form.Group className={styles.buttonDiv}>
                <Button
                    className={styles.buttonStyle}
                    disabled={formik.isSubmitting}
                    appearance="primary"
                    color="green"
                    type="submit"
                >
                    {formik.isSubmitting ? 'Processing' : 'Generate Selections'}
                </Button>
                {/* 
                <Button
                    className={styles.buttonStyle}
                    disabled={formik.isSubmitting}
                    appearance="default"
                    type="button"
                                 >
                    Cancel
                </Button> */}
            </Form.Group>
        </Form>
    );
};

export default MobileRandomSelectionForm;
