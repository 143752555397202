import React, { useState } from 'react';
import Button from 'rsuite/Button';
import toast from 'react-hot-toast';
import axios from 'axios';
import Grid from 'rsuite/Grid';
import Row from 'rsuite/Row';
import Col from 'rsuite/Col';
import FlexboxGrid from 'rsuite/FlexboxGrid';
import Input from 'rsuite/Input';
import { useDispatch, useSelector } from 'react-redux';
import TrashIcon from '@rsuite/icons/Trash';
import NumberFormat from 'react-number-format';
import styles from 'public/styles/GameComponents.module.css';
import {
    handleRemoveBetSlip,
    handleResetBetSlips,
    handleReSetComboDescription,
    handleResetGameBetType,
    handleSelectGame,
    handleSetLotteryCategory,
} from 'redux/actions/lotteryActions';
import ConfirmPlayModal from './ConfirmPlayModal';
import { currencyFormater, getUserToken } from 'utils';
import PlayBookedCodeModal from './PlayBookedCodeModal';
import BookedTicketSuccessModal from './BookedTicketSuccessModal';
import { useRouter } from 'next/router';
import TicketStatusModal from 'components/GamesComponents/PlayGame/TicketStatusModal';

function MobileBetSlipTable() {
    const { betSlips, potentialWinningData, activeGameInstance } = useSelector(
        (state) => state.lotteryData,
    );
    const { userData, walletInformation } = useSelector((state) => state?.userData);

    const dispatch = useDispatch();
    const router = useRouter();
    const { query } = useRouter();
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [openConfirmPlayModal, setOpenConfirmPlayModal] = React.useState(false);
    const [openTicketStatusModal, setOpenTicketStatusModal] = React.useState(false);
    const [openBookedTicketSuccessModal, setOpenBookedTicketSuccessModal] = React.useState(false);
    const [ticketStatusData, setTicketStatusData] = useState({});
    const [bookedTicketData, setBookedTicketData] = useState({});
    const [bookedCodeValue, setBookedCodeValue] = useState('');
    const [ticketIdField, setTicketIdField] = useState('');
    const [bookedCodeData, setBookedCodeData] = useState({});
    const [openBookedCodeModal, setOpenBookedCodeModal] = useState(false);
    const handleOpenConfirmPlayModal = () => {
        setIsSubmitting(true);
        setOpenConfirmPlayModal(true);
    };
    const handleCloseConfirmPlayModal = () => {
        // window.location.reload();
        setIsSubmitting(false);
        setOpenConfirmPlayModal(false);
    };
    const handleClosePlayBookedCodeModal = () => setOpenBookedCodeModal(false);
    const handleCloseTicketStatusModal = () => setOpenTicketStatusModal(false);
    const handleOpenBookedTicketSuccessModal = () => setOpenBookedTicketSuccessModal(true);
    const handleCloseBookedTicketSuccessModal = () => {
        setOpenBookedTicketSuccessModal(false);
        // window.location.reload();
    };

    const handleSaveTicket = async () => {
        // console.log('potential', potentialWinningData);
        try {
            if (!activeGameInstance?.gameId) {
                return toast.error('Select a game to continue');
            }
            setIsSubmitting(true);
            const token = getUserToken();
            let payload = {};
            payload.gameId = activeGameInstance?.gameId;
            payload.linesCount = potentialWinningData?.linesCount;
            payload.totalStakedAmount = potentialWinningData?.totalStakedAmount;
            payload.betSlips = potentialWinningData?.betSlips;

            const res = await axios.post(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/save-ticket`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );
            setBookedTicketData((prevState) => res?.data?.data?.data);
            setIsSubmitting(false);
            handleOpenBookedTicketSuccessModal();
            toast.success('Ticket Booked Successfully');
            dispatch(handleResetBetSlips());
        } catch (error) {
            console.log(error);
            setIsSubmitting(false);
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
        }
    };

    const handleFetchBookedCode = async () => {
        try {
            if (!bookedCodeValue) {
                return toast.error('Enter Booked Code To Proceed');
            }
            setIsSubmitting(true);
            const token = getUserToken();

            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-ticket-by-code/${bookedCodeValue}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );
            setBookedCodeData(res?.data?.data?.data);
            setOpenBookedCodeModal(true);
            setBookedCodeValue('');
        } catch (error) {
            console.log(error);
            setIsSubmitting(false);
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
        }
    };

    // Function to fetch a single ticket
    const handleFetchTicket = async () => {
        try {
            if (!ticketIdField) {
                return toast.error('Enter Ticket ID To Proceed');
            }
            setIsSubmitting(true);
            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-ticket/${ticketIdField}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );
            setTicketStatusData(res?.data?.data?.data);
            setOpenTicketStatusModal(true);
            setTicketIdField('');
        } catch (error) {
            console.log(error);
            setIsSubmitting(false);
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
        }
    };

    const handleChangeRouteByGame = async (item, lottery) => {
        dispatch(handleResetGameBetType());
        dispatch(handleResetBetSlips());
        dispatch(handleReSetComboDescription());
        dispatch(handleSetLotteryCategory(lottery?.category));
        router.push(`/lottery/${item?.lotteryId}/${lottery?.slug}?chosenGame=${item?.gameId}`);
        dispatch(handleSelectGame(item));
    };
    return (
        <div style={{ width: '100%', backgroundColor: 'green', borderRadius: '10px' }}>
            {openConfirmPlayModal ? (
                <ConfirmPlayModal
                    open={openConfirmPlayModal}
                    handleClose={handleCloseConfirmPlayModal}
                    activeGameInstance={activeGameInstance}
                    setIsSubmitting={setIsSubmitting}
                />
            ) : null}
            {openBookedCodeModal ? (
                <PlayBookedCodeModal
                    open={openBookedCodeModal}
                    handleClose={handleClosePlayBookedCodeModal}
                    bookedCodeData={bookedCodeData}
                />
            ) : null}
            {openTicketStatusModal ? (
                <TicketStatusModal
                    open={openTicketStatusModal}
                    handleClose={handleCloseTicketStatusModal}
                    ticketStatusData={ticketStatusData}
                />
            ) : null}
            {openBookedTicketSuccessModal ? (
                <BookedTicketSuccessModal
                    open={openBookedTicketSuccessModal}
                    handleClose={handleCloseBookedTicketSuccessModal}
                    successValue={bookedTicketData}
                />
            ) : null}

            <div className="betslip-header">Bet Slip</div>
            <div className="container-bg-color">
                <div className={styles.ticketCont}>
                    {betSlips?.length > 0 &&
                        betSlips?.map((line, idx) => (
                            <FlexboxGrid style={{ alignItems: 'stretch' }} key={idx}>
                                <FlexboxGrid.Item colspan={12}>
                                    <div className={styles.betSlipInfo}>
                                        <div>
                                            <small>
                                                {line?.betType
                                                    ? line?.betType
                                                    : line.overUnder?.over
                                                    ? 'over ' + line.overUnder.over
                                                    : line?.overUnder?.under
                                                    ? 'under ' + line?.overUnder?.under
                                                    : ''}
                                                {line?.resultType &&
                                                    line?.resultType !== 'null' &&
                                                    ` ${line?.resultType}`}
                                            </small>
                                        </div>
                                        <Row gutter={5}>
                                            <div>
                                                <small>
                                                    {line.betType.toLowerCase().includes('by')
                                                        ? line.selections
                                                        : line.selections
                                                              .split('-')
                                                              .slice(0, 4)
                                                              .join('-')}
                                                </small>
                                                <small>
                                                    {' '}
                                                    {line.selections.split('-').length > 4
                                                        ? '...'
                                                        : ''}
                                                </small>
                                            </div>
                                        </Row>
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6} className={styles.slipAmountDiv}>
                                    {
                                        <NumberFormat
                                            value={Number(line?.amount)}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            prefix={'NGN'}
                                            renderText={(value, props) => (
                                                <small {...props}>{value}</small>
                                            )}
                                        />
                                    }
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item
                                    colspan={6}
                                    className={`${styles.slipRmvButtonDiv} align-right`}
                                >
                                    <Button
                                        className={styles.slipRmvBtn}
                                        onClick={() =>
                                            dispatch(handleRemoveBetSlip(idx, query?.lotteryId))
                                        }
                                    >
                                        <TrashIcon />
                                    </Button>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                        ))}

                    <FlexboxGrid className="slip-space" justify="space-between">
                        <FlexboxGrid.Item colspan={12}>Total Stake</FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={12} className="align-right">
                            {
                                <NumberFormat
                                    value={potentialWinningData?.totalStakedAmount || 0}
                                    displayType={'text'}
                                    className={styles.totalSlipAmountStyle}
                                    thousandSeparator={true}
                                    prefix={'NGN'}
                                    renderText={(value, props) => <span {...props}>{value} </span>}
                                />
                            }
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </div>

                <FlexboxGrid className="slip-space" justify="space-between">
                    <FlexboxGrid.Item colspan={12}>Max Pot Win</FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12} className="align-right">
                        {typeof potentialWinningData?.totalPotentialWinning === 'number'
                            ? `NGN${currencyFormater(potentialWinningData?.totalPotentialWinning)}`
                            : potentialWinningData?.totalPotentialWinning?.includes('-')
                            ? `NGN${
                                  currencyFormater(
                                      potentialWinningData?.totalPotentialWinning?.split(' ')[2],
                                  ) || 0
                              }`
                            : `NGN${currencyFormater(potentialWinningData?.totalPotentialWinning)}`}
                    </FlexboxGrid.Item>
                </FlexboxGrid>
                <Grid fluid style={{ backgroundColor: 'black' }}>
                    <Row gutter={5}>
                        <Col xs={8}>
                            <div>
                                <Button
                                    color="red"
                                    style={{ textAlign: 'center', color: 'white' }}
                                    className="button-style"
                                    appearance="primary"
                                    disabled={betSlips?.length < 1 || isSubmitting}
                                    onClick={() => dispatch(handleResetBetSlips())}
                                    block
                                >
                                    Clear Slips
                                </Button>
                            </div>
                        </Col>

                        <Col xs={8}>
                            <div>
                                <Button
                                    color="red"
                                    style={{ textAlign: 'center', backgroundColor: '#ffb300' }}
                                    className="button-style2"
                                    appearance="primary"
                                    onClick={() => handleSaveTicket()}
                                    disabled={
                                        betSlips?.length < 1 || isSubmitting || userData?.isAgent
                                    }
                                    block
                                >
                                    Book
                                </Button>
                            </div>
                        </Col>

                        <Col xs={8}>
                            <div>
                                <Button
                                    style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        backgroundColor: '#193621',
                                    }}
                                    className="button-style"
                                    appearance="primary"
                                    block
                                    onClick={() => {
                                        if (userData?.isAgent) {
                                            if (!walletInformation?.hasMinimumDailyWalletBalance) {
                                                toast.error(
                                                    "Sorry, You haven't Reached your daily Wallet Limit, Please Fund Your Account to Continue",
                                                    { duration: 5000 },
                                                );
                                                return router.push('/deposit');
                                            }
                                        }
                                        handleOpenConfirmPlayModal();
                                    }}
                                    disabled={betSlips.length < 1 || isSubmitting}
                                >
                                    {isSubmitting ? 'Processing' : 'Play'}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Grid>
                <br />
            </div>

            <style jsx>
                {`
                    .betslip-header {
                        background-color: #ffb300;
                        padding: 20px 10px;
                        font-weight: bold;
                    }

                    .container-bg-color {
                        background-color: white;
                        color: black;
                    }
                `}
            </style>
        </div>
    );
}

export default MobileBetSlipTable;
