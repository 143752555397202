import React from 'react';
import Row from 'rsuite/Row';
import Col from 'rsuite/Col';
import Input from 'rsuite/Input';
import Button from 'rsuite/Button';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

function AddTicketForm({ betAmount, setBetAmount, handleAddToTicket }) {
    const { isAddingBetSlip } = useSelector((state) => state.lotteryData);
    const { userData, walletInformation } = useSelector((state) => state.userData);
    const router = useRouter();
    return (
        <Row style={{ padding: '5px 10px' }} gutter={5}>
            <Col xs={24} md={10}>
                <Input
                    placeholder="Enter Amount"
                    type="number"
                    value={betAmount}
                    onChange={(e) => setBetAmount(e)}
                    style={{ padding: '10px 20px', margin: '10px 0' }}
                />
            </Col>

            <Col xs={24} md={6}>
                <Button
                    disabled={!betAmount || betAmount <= 1 || isAddingBetSlip}
                    color="yellow"
                    className="button-style"
                    onClick={() => {
                        if (userData?.isAgent) {
                            if (!walletInformation?.hasMinimumDailyWalletBalance) {
                                toast.error(
                                    "Sorry, You haven't Reached your daily Wallet Limit, Please Fund Your Account to Continue",
                                    { duration: 5000 },
                                );
                                return router.push('/deposit');
                            }
                        }
                        handleAddToTicket();
                    }}
                    appearance="primary"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '10px',
                    }}
                >
                    {isAddingBetSlip ? 'Processing' : 'Add To Ticket'}
                </Button>
            </Col>
        </Row>
    );
}

export default AddTicketForm;
