import React, { useEffect } from 'react';
import UserLayout from 'components/Layouts/UserDefaultLayout';
import useSWR, { mutate } from 'swr';
import Container from 'rsuite/Container';
import Row from 'rsuite/Row';
import Col from 'rsuite/Col';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import GamesImageCard from 'components/HomepageComponents/GamesImageCard';
// import GameCard from 'components/HomepageComponents/GameCard';
import Pagination from 'rsuite/Pagination';
import { getUserToken, checkExpiredUserToken } from 'utils';
import GallerySlider from 'components/GallerySlider/GallerySlider';
import FullScreenError from 'components/shared/FullScreenError/FullScreenError';
import FullScreenLoader from 'components/shared/FullScreenLoader/FullScreenLoader';
import ChangePinNotification from 'components/ChangePinNotification/ChangePinNotification';
import MetaDecorator from 'components/UserComponents/MetaDecorator';
import { handleSetWalletInfo } from 'redux/actions/userActions';
import PlayBookedCodeModal from 'components/GamesComponents/PlayGame/PlayBookedCodeModal';
import TicketStatusModal from 'components/GamesComponents/PlayGame/TicketStatusModal';
import styles from 'public/styles/Homepage.module.css';
import MobileGameBoardView from 'components/HomepageComponents/MobileGameBoardView';
import { handleGetBanks } from 'redux/actions/banksAction';
import { handleGetAllBetOptions } from 'redux/actions/lotteryActions2';
import MobileBetSlipTable from 'components/HomepageComponents/MobileBetSlipTable';
import dayjs from 'dayjs';

export default function Index() {
    const limit = 12;
    const [page, setPage] = React.useState(1);
    const today = dayjs().day();
    const [openTicketStatusModal, setOpenTicketStatusModal] = React.useState(false);
    const [ticketStatusData, setTicketStatusData] = React.useState({});
    const [bookedCodeData, setBookedCodeData] = React.useState({});
    const [openBookedCodeModal, setOpenBookedCodeModal] = React.useState(false);
    const handleClosePlayBookedCodeModal = () => setOpenBookedCodeModal(false);
    const handleCloseTicketStatusModal = () => setOpenTicketStatusModal(false);

    const { userData } = useSelector((state) => state?.userData);
    const dispatch = useDispatch();

    const userURL = userData?.isAgent
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/agent/fetch-agent/${userData?.userId}`
        : `${process.env.NEXT_PUBLIC_BASE_URL}/user/fetch-user/${userData?.userId}`;

    const fetchUser = async () => {
        // console.log('pppppppppppppppppppppppp', process.env.NEXT_PUBLIC_BASE_URL);
        const token = getUserToken();
        const res = await axios.get(userURL, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return res.data?.data?.data;
    };

    const { data: user, error: userError } = useSWR(userURL, fetchUser);

    const url = `${
        process.env.NEXT_PUBLIC_BASE_URL
    }/game/fetch-current-game/?limit=${12}&page=${page}&currentWeekDay=${today}`;
    const fetcher = async () => {
        const token = getUserToken();
        dispatch(handleSetWalletInfo());
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return res.data?.data;
    };

    const handleChangePage = (newPage) =>
        mutate(url, async () => {
            try {
                setPage(newPage);
                const token = getUserToken();
                const res = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                });

                return res.data?.data;
            } catch (err) {
                // setIsFiltering(false);
                return err;
            }
        });

    useEffect(() => {
        // dispatch(handleSetWalletInfo());
        dispatch(handleGetBanks());
        dispatch(handleGetAllBetOptions());
    }, []);
    const { data, error } = useSWR(url, fetcher);

    if (error) {
        console.log(error);
        return <FullScreenError subtitle={error?.message} />;
    }

    if (!data) {
        return <FullScreenLoader />;
    }

    return (
        <UserLayout
            style={{ textAlign: 'center', background: '#193621' }}
            showSideBar={false}
            transparent={true}
        >
            <MetaDecorator title="Welcome - Yellow Lotto" />
            {user && user?.loginCount < 2 && <ChangePinNotification user={user} />}
            {openBookedCodeModal ? (
                <PlayBookedCodeModal
                    open={openBookedCodeModal}
                    handleClose={handleClosePlayBookedCodeModal}
                    bookedCodeData={bookedCodeData}
                />
            ) : null}

            <section className={styles.lotteryCont}>
                <Row gutter={12}>
                    <Col md={24} xs={24} className={styles.lotteryWrapper}>
                        <GallerySlider />
                        <div className={styles.gamesCardWrapper}>
                            <Container>
                                <Row gutter={12}>
                                    {data?.data?.length ? (
                                        data?.data?.map((item, idx) => (
                                            <Col lg={6} md={8} sm={8} xs={24} key={item?.gameId}>
                                                <GamesImageCard game={item} />
                                            </Col>
                                        ))
                                    ) : (
                                        <div
                                            style={{
                                                height: '40vh',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <h5>Sorry, there are currently no games</h5>
                                        </div>
                                    )}
                                </Row>
                            </Container>
                        </div>

                        <div className={styles.paginationDiv}>
                            <Pagination
                                prev
                                last
                                maxButtons={5}
                                next
                                first
                                ellipsis
                                size="sm"
                                total={Number(data?.totalCount)}
                                limit={limit}
                                activePage={Number(page)}
                                onChangePage={handleChangePage}
                            />
                        </div>
                    </Col>
                </Row>
            </section>
            <div className={styles.mobileOnly}>
                <MobileGameBoardView />
                <MobileBetSlipTable />
            </div>
        </UserLayout>
    );
}
