import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import Button from 'rsuite/Button';
import SelectPicker from 'rsuite/SelectPicker';
import {
    ALL_LOTTERIES_BETOPTIONS,
    formatGameDate,
    LOTTO_CONTINENTAL,
    POOL_OF_FAME,
    renderOverUnderValue,
    safeJSONParse,
    safeJSONParseObj,
} from 'utils';
import styles from './MobileGameOptions.module.css';
import {
    EMPTY_STRING,
    handleSetGameBetOption,
    handleSetGameResultOption,
} from 'redux/actions/lotteryActions';
import { useRouter } from 'next/router';
import InstantGamesTimer from './InstantGamesTimer';
import MobileGameTimer, { MobilePOFGameTimer } from './MobileGameTimer';

function MobileGameOptions({
    gameData,
    selectedBooster,
    setSelectedBooster,
    resultOptionSelect,
    betOptionSelect,
    overOptionSelect,
    underOptionSelect,
    setResultOptionSelect,
    setBetOptionSelect,
    setClickedBalls,
    setIsRandomSelection,
    handleSelectOverOptions,
    handleSelectUnderOptions,
}) {
    const { walletInformation } = useSelector((state) => state.userData);

    const dispatch = useDispatch();
    const betOptions = safeJSONParse(gameData?.Lottery?.betOptions);
    const overOptions = safeJSONParse(gameData?.Lottery?.overOptions);
    const underOptions = safeJSONParse(gameData?.Lottery?.underOptions);
    const boosterOptions = safeJSONParse(gameData?.Lottery?.boosters);
    let resultOptions = safeJSONParse(gameData?.Lottery?.resultOptions);

    const router = useRouter();
    const { lotteryCategory, lotteryId } = router.query;

    const handleSelectResultType = (e) => {
        setResultOptionSelect((prev) => e);
        dispatch(handleSetGameResultOption(e));
    };
    const handleSelectBetType = (e) => {
        if (e === EMPTY_STRING) {
            setIsRandomSelection((prev) => false);
        }
        setClickedBalls((prev) => []);
        setBetOptionSelect((prev) => e);
        dispatch(handleSetGameBetOption(e));
        router.replace({ query: { ...router.query, betType: e } });
    };
    if (betOptionSelect.includes('against')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg',
        );
    }

    if (betOptionSelect.includes('w-by-')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg' &&
                option !== 'double-chance' &&
                option !== 'machine',
        );
    }
    // console.log('selectedbooster', selectedBooster);
    if (betOptionSelect.includes('-by-') && selectedBooster === 'turning') {
        resultOptions = resultOptions?.filter((option) => option !== 'turning');
    }
    if (betOptionSelect.includes('nap') && selectedBooster === 'turning') {
        resultOptions = resultOptions?.filter((option) => option !== 'turning');
    }

    if (betOptionSelect.includes('perm')) {
        resultOptions = resultOptions?.filter((option) => option !== 'no-leg');
    }
    if (betOptionSelect.includes('perm') && selectedBooster === 'turning') {
        resultOptions = resultOptions?.filter((option) => option !== 'turning');
    }

    if (betOptionSelect.includes('fnd')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg' &&
                option !== 'double-chance' &&
                option !== 'machine',
        );
    }
    if (betOptionSelect.includes('1st-box')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg' &&
                option !== 'double-chance' &&
                option !== 'machine',
        );
    }

    if (betOptionSelect.includes('2nd-box')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg' &&
                option !== 'double-chance' &&
                option !== 'machine',
        );
    }
    if (betOptionSelect.includes('center-box')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg' &&
                option !== 'double-chance' &&
                option !== 'machine',
        );
    }
    if (betOptionSelect.includes('no-draw')) {
        resultOptions = resultOptions?.filter(
            (option) =>
                option !== 'turning' &&
                option !== 'recovery' &&
                option !== '1-leg' &&
                option !== 'no-leg' &&
                option !== 'double-chance' &&
                option !== 'machine' &&
                option !== 'winning',
        );
    }

    useEffect(() => {
        if (
            router?.query?.lotteryCategory &&
            router?.query?.lotteryCategory === LOTTO_CONTINENTAL
        ) {
            handleSelectResultType('global');
        }
    }, [router?.query?.lotteryCategory]);

    let newGameOptions = betOptions.map((item) => ({ label: item?.name, value: item?.name }));
    if (walletInformation?.firstname) {
        const foundLottery = walletInformation?.excludedAgentBetTypes?.find(
            (item) => item?.lotteryId === lotteryId,
        );

        if (foundLottery && foundLottery?.betTypes) {
            newGameOptions = [];
            betOptions?.map((item) => {
                if (!foundLottery?.betTypes?.includes(item?.name)) {
                    newGameOptions?.push({ label: item?.name, value: item?.name });
                }
            });
        }
    }
    return (
        <div className={styles.gameOptionsCont}>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    gap: '5px',
                    justifyContent: 'center',
                }}
            >
                {gameData?.drawMethod === 'instant' ? (
                    <InstantGamesTimer gameData={gameData} />
                ) : lotteryCategory === POOL_OF_FAME ? (
                    <MobilePOFGameTimer gameData={gameData} />
                ) : (
                    <MobileGameTimer gameData={gameData} />
                )}
            </div>
            {gameData?.name &&
            gameData?.drawMethod !== 'instant' &&
            lotteryCategory !== POOL_OF_FAME ? (
                <div className={styles.gameTimeStyle}>
                    <div className={styles.gameTimeInnerStyle}>
                        <p>Start Time:</p>
                        <p className={styles.timeText}>{formatGameDate(gameData?.startTime)}</p>
                    </div>
                    <div className={styles.gameTimeInnerStyle}>
                        <p>End Time:</p>
                        <p className={styles.timeText}>{formatGameDate(gameData?.endTime)}</p>
                    </div>
                </div>
            ) : null}

            <div className={styles.formContainer}>
                {betOptions && betOptions?.length > 0 && (
                    <>
                        <div className={styles.fullSelectDivStyle}>
                            <p>
                                <small>Bet Type </small>
                            </p>
                            {betOptions.length > 0 ? (
                                <SelectPicker
                                    cleanable={false}
                                    searchable={false}
                                    disabled={
                                        (overOptionSelect !== EMPTY_STRING &&
                                            overOptionSelect !== '') ||
                                        (underOptionSelect !== EMPTY_STRING &&
                                            underOptionSelect !== '')
                                    }
                                    style={{ width: '100%' }}
                                    data={[
                                        { value: EMPTY_STRING, label: EMPTY_STRING },
                                        ...newGameOptions,
                                    ]}
                                    valueKey="value"
                                    labelKey="label"
                                    value={betOptionSelect}
                                    placeholder={EMPTY_STRING}
                                    onChange={(e) => {
                                        handleSelectBetType(e);
                                    }}
                                    onClean={(e) => {
                                        handleSelectBetType('');
                                    }}
                                />
                            ) : (
                                <Button type="button" size="sm">
                                    N/A
                                </Button>
                            )}
                        </div>
                        {resultOptions.length <= 0 || betOptionSelect.includes('no-draw') ? null : (
                            <div className={styles.col}>
                                <p>
                                    <small>Result Type </small>
                                </p>
                                <SelectPicker
                                    disabled={
                                        (overOptionSelect !== EMPTY_STRING &&
                                            overOptionSelect !== '') ||
                                        (underOptionSelect !== EMPTY_STRING &&
                                            underOptionSelect !== '') ||
                                        !betOptionSelect
                                    }
                                    valueKey="value"
                                    labelKey="label"
                                    cleanable={false}
                                    searchable={false}
                                    style={{ width: '100%' }}
                                    data={[
                                        { value: EMPTY_STRING, label: EMPTY_STRING },
                                        ...resultOptions?.map((item) => ({
                                            value: item,
                                            label: item,
                                        })),
                                    ]}
                                    value={resultOptionSelect}
                                    placeholder="----"
                                    onChange={(e) => {
                                        handleSelectResultType(e);
                                    }}
                                />
                            </div>
                        )}
                        <div className={styles.col}>
                            {overOptions.length <= 0 ? null : (
                                <>
                                    {overOptions.length > 0 ? (
                                        <>
                                            <p>
                                                <small>Over </small>
                                            </p>
                                            <SelectPicker
                                                disabled={
                                                    betOptionSelect || underOptionSelect !== ''
                                                }
                                                valueKey="value"
                                                labelKey="label"
                                                cleanable={false}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                data={[
                                                    { value: EMPTY_STRING, label: EMPTY_STRING },
                                                    ...overOptions?.map((item) => ({
                                                        value: renderOverUnderValue(item, 'value'),
                                                        label: renderOverUnderValue(item, 'name'),
                                                    })),
                                                ]}
                                                value={overOptionSelect}
                                                placeholder="----"
                                                onChange={(e) => {
                                                    handleSelectOverOptions(e);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                        <div className={styles.col}>
                            {underOptions.length <= 0 ? null : (
                                <>
                                    {underOptions.length > 0 ? (
                                        <>
                                            <p>
                                                <small>Under </small>
                                            </p>
                                            <SelectPicker
                                                disabled={overOptionSelect || betOptionSelect}
                                                valueKey="value"
                                                labelKey="label"
                                                cleanable={false}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                data={[
                                                    { value: EMPTY_STRING, label: EMPTY_STRING },
                                                    ...underOptions?.map((item) => ({
                                                        value: renderOverUnderValue(item, 'value'),
                                                        label: renderOverUnderValue(item, 'name'),
                                                    })),
                                                ]}
                                                value={underOptionSelect}
                                                placeholder="----"
                                                onChange={(e) => {
                                                    handleSelectUnderOptions(e);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                        <div className={styles.col}>
                            {boosterOptions.length <= 0 ? null : (
                                <>
                                    {boosterOptions.length > 0 ? (
                                        <>
                                            <p>
                                                <small>Boosters </small>
                                            </p>
                                            <SelectPicker
                                                disabled={overOptionSelect || betOptionSelect}
                                                valueKey="value"
                                                labelKey="label"
                                                cleanable={false}
                                                searchable={false}
                                                style={{ width: '100%' }}
                                                data={[
                                                    { value: EMPTY_STRING, label: EMPTY_STRING },
                                                    ...boosterOptions?.map((item) => ({
                                                        value: item,
                                                        label: item,
                                                    })),
                                                ]}
                                                value={selectedBooster}
                                                placeholder="----"
                                                onChange={(e) => {
                                                    setSelectedBooster(e);
                                                }}
                                            />
                                        </>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
            {/* {!betOptionSelect || betOptionSelect === EMPTY_STRING ? null : (
                <div>
                    <Checkbox
                        checked={isRandomSelection}
                        value={isRandomSelection}
                        onChange={() => handleChangeRandomSelection()}
                    >
                        Allow random system generated selections
                    </Checkbox>

                    <br />
                    {betOptionSelect && betOptionSelect !== EMPTY_STRING && (
                        <>
                            {isRandomSelection ? (
                                <RandomSelectionForm
                                    gameData={gameData}
                                    resultOptionSelect={resultOptionSelect}
                                    selectedBooster={selectedBooster}
                                    betOptionSelect={betOptionSelect}
                                />
                            ) : null}{' '}
                        </>
                    )}
                </div>
            )} */}
        </div>
    );
}

export default MobileGameOptions;
