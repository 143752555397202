import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Grid from 'rsuite/Grid';
import Row from 'rsuite/Row';
import Checkbox from 'rsuite/Checkbox';
import Container from 'rsuite/Container';
import Button from 'rsuite/Button';
import { useDispatch, useSelector } from 'react-redux';
// import { GameChoiceBottom } from './GameChoice';

import AddTicketForm from './AddTicketForm';
import styles from './MobileGameBoardView.module.css';
import {
    ALL_LOTTERIES_BETOPTIONS,
    LOTTO_CONTINENTAL,
    getNextRunningGame,
    getUserToken,
    renderCategoryDisplay,
    safeJSONParse,
} from 'utils';
import dayjs from 'dayjs';
import {
    EMPTY_STRING,
    handleSelectGame,
    handleSetGameBetOption,
    handleSetGameBetTypeDesc,
    handleUpdateBetSlips,
} from 'redux/actions/lotteryActions';
import useSWR from 'swr';
import axios from 'axios';
import TableLoader from 'components/shared/TableLoader/TableLoader';
import TableError from 'components/shared/TableError/TableError';
import MobileGameBoard from './MobileGameBoard';
import MobileGameOptions from './MobileGameOptions';
import MobileRandomSelectionForm from './MobileRandomSelectionForm';

function MobileGameBoardView({}) {
    const [isRandomSelection, setIsRandomSelection] = useState(false);
    const [betAmount, setBetAmount] = useState(50);
    const [clickedBalls, setClickedBalls] = useState([]);
    const [setAClickedBalls, setSetAClickedBalls] = useState([]);
    const [setBClickedBalls, setSetBClickedBalls] = useState([]);
    const [resultOptionSelect, setResultOptionSelect] = useState('');
    const [selectedBooster, setSelectedBooster] = useState('');
    const [betOptionSelect, setBetOptionSelect] = useState('');
    const [overOptionSelect, setOverOptionSelect] = useState('');
    const [underOptionSelect, setUnderOptionSelect] = useState('');
    const [activeSet, setActiveSet] = useState('');
    const { gameBetOption, activeGameInstance, gameResultOption } = useSelector(
        (state) => state.lotteryData,
    );
    const { userData, walletInformation } = useSelector((state) => state.userData);
    const today = dayjs().day();

    const dispatch = useDispatch();
    const router = useRouter();
    const { lotteryCategory, lotteryId } = router?.query;
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-game/${router?.query?.gameId}`;
    const url2 = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-current-game?currentWeekDay=${today}&limit=30`;
    const handleCheckBalanceLimit = () => {
        if (userData?.isAgent) {
            if (!walletInformation?.hasMinimumDailyWalletBalance) {
                toast.error(
                    "Sorry, You haven't Reached your daily Wallet Limit, Please Fund Your Account to Continue",
                    { duration: 5000 },
                );
                router.push('/deposit');
            }
        }
    };
    const handleReseFields = async () => {
        setBetAmount(50);
        setClickedBalls([]);
        setSetAClickedBalls([]);
        setSetBClickedBalls([]);
        setResultOptionSelect('');
        setSelectedBooster('');
        setBetOptionSelect('');
        setOverOptionSelect('');
        setUnderOptionSelect('');

        await dispatch(handleSetGameBetTypeDesc(''));
        await dispatch(handleSetGameBetOption(EMPTY_STRING));
        // setSelectedGame('');
    };
    const fetcher = async () => {
        const token = getUserToken();
        let res = {};
        if (router.query?.gameId) {
            let temp = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });
            dispatch(handleSelectGame(temp?.data?.data?.data));
            res = temp?.data?.data?.data;
        } else {
            const res2 = await axios.get(url2, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });

            let game = {};
            game = getNextRunningGame(res2.data?.data?.data);
            dispatch(handleSelectGame(game));
            res = { ...game };

            if (game?.gameId) {
                router.replace({
                    query: {
                        ...router.query,
                        lotteryId: game.lotteryId,
                        lotteryName: game?.lotteryName,
                        lotteryCategory: game?.Lottery?.category,
                        gameId: game?.gameId,
                        lotterySlug: game?.Lottery?.slug,
                    },
                });
            }
        }
        return res;
    };
    const handleAddToTicket = async () => {
        if (!userData?.firstname) return router.push('/login');
        handleCheckBalanceLimit();
        let payload = {
            betType: '',
            // booster: '',
            // resultType: '',
        };
        let allBetOptions;
        if (typeof window === 'object') {
            let items = sessionStorage.getItem(ALL_LOTTERIES_BETOPTIONS);
            if (items) {
                allBetOptions = JSON.parse(items);
            }
        }
        let selectedBetOption =
            gameBetOption || allBetOptions[router?.query?.lotteryCategory][router?.query?.betType];
        let overUnder = {};
        let selections = '';

        if (activeGameInstance === null || !activeGameInstance?.gameId) {
            return toast.error('No active game');
        }

        // Checks if no game option is selected
        if (
            (!betOptionSelect || betOptionSelect === EMPTY_STRING) &&
            (overOptionSelect === EMPTY_STRING || !overOptionSelect) &&
            (underOptionSelect === EMPTY_STRING || !underOptionSelect)
        ) {
            return toast.error('Select any game bet option');
        }

        payload.amount = Number(betAmount);
        if (
            selectedBetOption?.value &&
            (overOptionSelect === EMPTY_STRING || overOptionSelect === '') &&
            (underOptionSelect === EMPTY_STRING || underOptionSelect === '')
        ) {
            payload.betType = selectedBetOption.value || betOptionSelect || router?.query?.betType;
        }
        // console.log('nnnnnnnnnnn', betOptionSelect);
        if (
            gameResultOption?.value &&
            resultOptionSelect !== EMPTY_STRING &&
            resultOptionSelect !== ''
        ) {
            payload.resultType = gameResultOption?.value;
        }
        if (!resultOptionSelect && lotteryCategory === LOTTO_CONTINENTAL) {
            payload.resultType = 'global';
        }

        if (overOptionSelect !== EMPTY_STRING && overOptionSelect !== '') {
            overUnder.over = Number(overOptionSelect);
            payload.overUnder = overUnder;
        }
        if (underOptionSelect !== EMPTY_STRING && underOptionSelect !== '') {
            overUnder.under = Number(underOptionSelect);
            payload.overUnder = overUnder;
        }
        if (selectedBooster) {
            payload.booster = selectedBooster;
        }

        // If gameBetOption type has set A and set B

        if (selectedBetOption?.isSetAB) {
            if (
                setAClickedBalls?.length < selectedBetOption?.min ||
                setBClickedBalls?.length < selectedBetOption?.min
            ) {
                return toast.error(
                    `Selections must be more than or equal to ${selectedBetOption?.min} and less than ${selectedBetOption?.max}`,
                );
            }

            setAClickedBalls.forEach((elem, index) => {
                selections += index < setAClickedBalls?.length - 1 ? `${elem}-` : `${elem}`;
            });
            selections += '/';
            setBClickedBalls.forEach((elem, index) => {
                selections += index < setBClickedBalls?.length - 1 ? `${elem}-` : `${elem}`;
            });
            payload.selections = selections;

            // dispatch action to update the betslips
            await dispatch(handleUpdateBetSlips(payload, lotteryId));
            return handleReseFields();
        }

        // Checks if the game mode is over or under
        if (
            (overOptionSelect !== EMPTY_STRING && overOptionSelect !== '') ||
            (underOptionSelect !== EMPTY_STRING && underOptionSelect !== '')
        ) {
            payload.selections = '';

            // dispatch action to update the betslips
            await dispatch(handleUpdateBetSlips(payload, lotteryId));
            return handleReseFields();
        }

        if (clickedBalls?.length < selectedBetOption?.min) {
            return toast.error(
                `Selections must be more than or equal to ${selectedBetOption?.min} and less than ${selectedBetOption?.max}`,
            );
        }

        clickedBalls.forEach((elem, index) => {
            selections += index < clickedBalls?.length - 1 ? `${elem}-` : `${elem}`;
        });

        payload.selections = selections;

        // dispatch action to update the betslips
        await dispatch(handleUpdateBetSlips(payload, lotteryId));
        return handleReseFields();
    };

    const handleChangeRandomSelection = () => setIsRandomSelection((curr) => !curr);
    const handleSelectOverOptions = (value) => {
        setOverOptionSelect(value);
    };
    const handleSelectUnderOptions = (value) => {
        setUnderOptionSelect(value);
    };
    const { data, error } = useSWR(url, fetcher);

    if (error) {
        // console.log('eeeeeeeeeeeeeee', error);
        return <TableError color="black" subtitle={error?.message} />;
    }

    if (!data) return <TableLoader />;

    return (
        <Container>
            <h6 style={{ marginTop: '15px', paddingLeft: '3px' }}>
                {renderCategoryDisplay(lotteryCategory)} &rarr; {data?.name}
            </h6>
            <MobileGameOptions
                gameData={data}
                betOptionSelect={betOptionSelect}
                overOptionSelect={overOptionSelect}
                underOptionSelect={underOptionSelect}
                resultOptionSelect={resultOptionSelect}
                selectedBooster={selectedBooster}
                setSelectedBooster={setSelectedBooster}
                setBetOptionSelect={setBetOptionSelect}
                setResultOptionSelect={setResultOptionSelect}
                setClickedBalls={setClickedBalls}
                setIsRandomSelection={setIsRandomSelection}
                handleSelectOverOptions={handleSelectOverOptions}
                handleSelectUnderOptions={handleSelectUnderOptions}
            />
            <div>
                <MobileGameBoard
                    gameData={data}
                    selectedBooster={selectedBooster}
                    resultOptionSelect={resultOptionSelect}
                    betOptionSelect={betOptionSelect}
                    overOptionSelect={overOptionSelect}
                    underOptionSelect={underOptionSelect}
                    setResultOptionSelect={setResultOptionSelect}
                    setBetOptionSelect={setBetOptionSelect}
                    setOverOptionSelect={setOverOptionSelect}
                    setUnderOptionSelect={setUnderOptionSelect}
                    clickedBalls={clickedBalls}
                    setClickedBalls={setClickedBalls}
                    handleChangeRandomSelection={handleChangeRandomSelection}
                    isRandomSelection={isRandomSelection}
                    setAClickedBalls={setAClickedBalls}
                    setBClickedBalls={setBClickedBalls}
                    setSetAClickedBalls={setSetAClickedBalls}
                    setSetBClickedBalls={setSetBClickedBalls}
                    activeSet={activeSet}
                    setIsRandomSelection={setIsRandomSelection}
                />
            </div>
            {betOptionSelect && (
                <div>
                    <Checkbox
                        checked={isRandomSelection}
                        value={isRandomSelection}
                        onChange={() => handleChangeRandomSelection()}
                    >
                        Use System Generated Selections
                    </Checkbox>
                </div>
            )}
            {isRandomSelection ? (
                <MobileRandomSelectionForm
                    betOptionSelect={betOptionSelect}
                    gameData={data}
                    resultOptionSelect={resultOptionSelect}
                    selectedBooster={selectedBooster}
                />
            ) : null}
            {gameBetOption?.value && gameBetOption?.isSetAB && (
                <div>
                    <div className={styles.setABRow}>
                        <div className={styles.setABRowInner}>
                            <div
                                className={styles.setLabel}
                                style={{
                                    backgroundColor: activeSet === 'A' ? 'green' : 'red',
                                }}
                                onClick={() =>
                                    setActiveSet((prevValue) => (prevValue === 'A' ? '' : 'A'))
                                }
                            >
                                Set A
                            </div>

                            <div
                                className={styles.setSelectionDiv}
                                id="setA"
                                style={{
                                    border:
                                        activeSet === 'A'
                                            ? '2px solid green'
                                            : '2px solid transparent',
                                }}
                            >
                                {setAClickedBalls?.map((a, i) => (
                                    <Button className="balls-green" key={i}>
                                        {a < 10 ? '0' + a.toString() : a.toString()}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.setABRow}>
                        <div className={styles.setABRowInner}>
                            <div
                                className={styles.setLabel}
                                style={{
                                    backgroundColor: activeSet === 'B' ? 'green' : 'red',
                                }}
                                onClick={() =>
                                    setActiveSet((prevValue) => (prevValue === 'B' ? '' : 'B'))
                                }
                            >
                                Set B
                            </div>
                            <div
                                className={styles.setSelectionDiv}
                                id="setB"
                                style={{
                                    border:
                                        activeSet === 'B'
                                            ? '2px solid green'
                                            : '2px solid transparent',
                                }}
                            >
                                {setBClickedBalls?.map((a, i) => (
                                    <Button className="balls-green" key={i}>
                                        {a < 10 ? '0' + a.toString() : a.toString()}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <AddTicketForm
                betAmount={betAmount}
                setBetAmount={setBetAmount}
                handleAddToTicket={handleAddToTicket}
            />
        </Container>
    );
}

export default MobileGameBoardView;
