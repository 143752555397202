import toast from 'react-hot-toast';
import React from 'react';

// import GameOptions from './GameOptions';
import { useSelector } from 'react-redux';
import { EMPTY_STRING } from 'redux/actions/lotteryActions';
import styles from './MobileGameBoard.module.css';
import {
    FIVE_0F_NINETY,
    LEGENDARY_LOTTO,
    getBetOptionFromStorage,
    isNoSelectionBetType,
} from 'utils';
// import OverUnderOptions from './OverUnderOptions';
import { useRouter } from 'next/router';

function MobileGameBoard({
    gameData,
    resultOptionSelect,
    betOptionSelect,
    overOptionSelect,
    underOptionSelect,
    setResultOptionSelect,
    setBetOptionSelect,
    setOverOptionSelect,
    setUnderOptionSelect,
    clickedBalls,
    setClickedBalls,
    setAClickedBalls,
    setBClickedBalls,
    setSetAClickedBalls,
    setSetBClickedBalls,
    activeSet,
    handleChangeRandomSelection,
    isRandomSelection,
    setIsRandomSelection,
}) {
    const { betTypeDescription, gameBetOption, activeGameInstance, comboDescription } = useSelector(
        (state) => state.lotteryData,
    );

    const router = useRouter();

    const { lotteryCategory } = router?.query;

    const maxSelectionErrorMsg =
        'Maximum selection reached, you can un-select some balls and re-select a new one.';

    const handleClickBall = (selectedBall) => {
        if (!overOptionSelect && !underOptionSelect && !betOptionSelect && !resultOptionSelect) {
            return toast.error(
                'Select a game option to continue; Bet Type or Result Type or Over or Under Options',
            );
        }
        if (
            (overOptionSelect && overOptionSelect !== EMPTY_STRING) ||
            (underOptionSelect && underOptionSelect !== EMPTY_STRING)
        ) {
            return toast.error('You do not need to select any balls in this mode');
        }
        if (betOptionSelect && isNoSelectionBetType(betOptionSelect)) {
            return toast.error('You do not need to select any balls for this bet option');
        }
        let selectedOption = gameBetOption || getBetOptionFromStorage();
        if (activeSet === '' && selectedOption?.isSetAB) {
            return toast.error('Select a game set, either Set A or Set B to continue');
        }
        if (activeSet === 'A' && selectedOption?.isSetAB) {
            let ballIndex = setAClickedBalls?.indexOf(selectedBall);
            if (ballIndex >= 0) {
                return setSetAClickedBalls((prevState) =>
                    prevState.filter((ball) => ball !== selectedBall),
                );
            }
            if (setAClickedBalls?.length >= selectedOption?.max) {
                return toast.error(maxSelectionErrorMsg);
            }
            setSetAClickedBalls((prevState) => {
                return [...prevState, selectedBall];
            });
            return;
        }

        if (activeSet === 'B' && selectedOption?.isSetAB) {
            let ballIndex = setBClickedBalls?.indexOf(selectedBall);
            if (ballIndex >= 0) {
                return setSetBClickedBalls((prevState) =>
                    prevState.filter((ball) => ball !== selectedBall),
                );
            }
            if (setBClickedBalls?.length >= selectedOption?.max) {
                return toast.error(maxSelectionErrorMsg);
            }
            setSetBClickedBalls((prevState) => {
                return [...prevState, selectedBall];
            });

            return;
        }

        let gameBallIndex = clickedBalls?.indexOf(selectedBall);
        if (gameBallIndex >= 0) {
            return setClickedBalls((prevState) =>
                prevState.filter((ball) => ball !== selectedBall),
            );
        }
        if (clickedBalls?.length >= selectedOption?.max) {
            return toast.error(maxSelectionErrorMsg);
        }
        setClickedBalls((prevState) => {
            return [...prevState, selectedBall];
        });
    };
    // console.log('betTypeDescription', gameData);
    return (
        <div style={{ padding: '5px 0' }}>
            <div style={{ width: '100%' }}>
                {lotteryCategory === FIVE_0F_NINETY ||
                lotteryCategory === LEGENDARY_LOTTO ? null : (
                    <p style={{ marginBottom: '7px', padding: '3px' }}>
                        Description: {betTypeDescription}
                    </p>
                )}

                {comboDescription && (
                    <p style={{ marginBottom: '15px', padding: '3px' }}>
                        Description: {comboDescription}
                    </p>
                )}

                <section className={styles.gameBallsSection}>
                    <div className={styles.gameBallsLeftSection}>
                        {Array.from(Array(gameData?.Lottery?.gameCount).keys(), (x) => x + 1).map(
                            (a, idx) => (
                                <div className={styles.singleBallContainer} key={a}>
                                    <div
                                        className={`${
                                            !clickedBalls.includes(a)
                                                ? styles.yellowWhiteGameBall
                                                : styles.activeBall
                                        }`}
                                        onClick={() => handleClickBall(a)}
                                    >
                                        <section className={styles.yellowWhite3dGameBallInner}>
                                            <section className={styles.white3dMainInner}>
                                                <span className={styles.numberText}>
                                                    {a < 10 ? '0' + a.toString() : a.toString()}
                                                </span>
                                            </section>
                                        </section>
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                </section>
            </div>
        </div>
    );
}

export default MobileGameBoard;
