import React, { memo } from 'react';
import Countdown from 'react-countdown';
import styles from 'public/styles/GameComponents.module.css';
import { useSelector } from 'react-redux';
import { getActiveGameFromStorage } from 'utils';

const InstantGamesTimer = () => {
    const { activeGameInstance } = useSelector((state) => state.lotteryData);
    const game = getActiveGameFromStorage();
    const runningGame = activeGameInstance || game;
    let gameStartTime = new Date();
    let gameEndTime = new Date();

    const startTimeArray = runningGame?.startTime?.split(':');
    const endTimeArray = runningGame?.endTime?.split(':');

    if (runningGame && runningGame?.endTime) {
        gameStartTime.setHours(Number(startTimeArray[0]) + 1);
        gameEndTime.setHours(Number(endTimeArray[0]) + 1);

        gameStartTime.setMinutes(Number(startTimeArray[1]));
        gameEndTime.setMinutes(Number(endTimeArray[1]));
    }

    const renderer = ({ hours, minutes, seconds }) => {
        return (
            <span
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '5px',
                    padding: '5px 25px',
                }}
            >
                {hours}:{minutes}:{seconds}
            </span>
        );
    };

    let currentHour = new Date().getHours();

    if (!runningGame?.endTime) {
        return null;
    }

    if (currentHour < gameStartTime.getHours()) {
        return (
            <div
                style={{
                    color: 'white',
                    backgroundColor: 'green',
                    borderRadius: '5px',
                    padding: '5px 5px',
                }}
            >
                Upcomming
            </div>
        );
    }
    if (currentHour > Number(gameEndTime.getHours())) {
        return (
            <div
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '5px',
                    padding: '5px 5px',
                }}
                className={styles.expiredGame}
            >
                Game Time Elapsed
            </div>
        );
    }

    return (
        <>
            {runningGame?.gameId ? (
                <Countdown intervalDelay={0} precision={3} date={gameEndTime} renderer={renderer} />
            ) : null}
        </>
    );
};

export default InstantGamesTimer;
