import React from 'react';
import ReactToPrint from 'react-to-print';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    PinterestShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    TelegramIcon,
    PinterestIcon,
    EmailIcon,
} from 'react-share';
import styles from './ComponentToPrint.module.css';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import QRCode from 'qrcode';
import { FaThumbsUp } from 'react-icons/fa';
import Image from 'next/image';
import { handleResetBetSlips } from 'redux/actions/lotteryActions';
import { currencyFormater, renderHostURL } from 'utils';

export const ComponentToPrint = React.forwardRef((props, ref) => {
    const { QRImage, successValue } = props;

    // console.log('bbbbbbbbbbbbb', successValue);
    return (
        <div ref={ref} className={styles.printContainerStyle}>
            <Image
                src={'/images/logo2.jpeg'}
                alt="Gaim"
                width={90}
                height={40}
                className={styles.printContainerImage}
            />
            {successValue &&
                successValue?.betSlips?.length > 0 &&
                successValue?.betSlips?.map((item, idx) => (
                    <div key={`${idx}nn`} className={styles.betSlipsTableBodyRow}>
                        <h6 className={styles.tableBodyCell}>
                            {item?.selections ? `${item?.selections}/` : null}
                            {item?.overUnder ? `${item?.overUnder}/` : null}
                            {successValue?.linesCount}/{currencyFormater(item?.amount)}/
                            {currencyFormater(item?.potentialWinning)}
                        </h6>
                        <h6 className={styles.tableBodyCell}>
                            {item?.betType ? `${item?.betType}` : null}/{item?.resultType}
                        </h6>
                        <p>------------------------------------------------------------------</p>
                    </div>
                ))}
            <section
                className={styles.printBottomDiv}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
            >
                <div>
                    <h6 className={styles.tableBodyCell}>Game: {successValue?.Game?.name}</h6>
                    <h6 className={styles.tableBodyCell}>Ticket ID: {successValue?.ticketId}</h6>
                    <h6 className={styles.tableBodyCell}>Terminal ID: {successValue?.ticketId}</h6>
                    <h6 className={styles.tableBodyCell}>Agent ID: {successValue?.ticketId}</h6>
                    <h6 className={styles.tableBodyCell}>Date: {dayjs().format('DD/MM/YYYY')}</h6>
                </div>{' '}
                {QRImage && (
                    <div style={{ width: '76px', height: '76px' }}>
                        <Image
                            unoptimized
                            alt="ticket qrcode"
                            src={QRImage}
                            width={76}
                            height={76}
                        />
                    </div>
                )}
            </section>
        </div>
    );
});

const BookedTicketSuccessModal = ({ open, handleClose, successValue }) => {
    // const componentRef = React.useRef(null);
    const dispatch = useDispatch();
    // const onBeforeGetContentResolve = React.useRef(null);
    // const [QRImage, setQRImage] = React.useState(null);

    let totalPotentialWinning = 0;
    let totalSelections = '';
    successValue?.betSlips?.forEach((item) => {
        if (item?.selections) {
            totalSelections = totalSelections + ', ' + item?.selections;
        }
        totalPotentialWinning += Number(item?.potentialWinning);
    });
    const title = `Hey, I just booked a ticket on ${
        successValue?.Game?.name
    } lottery on Gaim, my booking code is ${successValue?.bookingCode} with Ticket ID: ${
        successValue?.ticketId
    } and I am going to win ${currencyFormater(totalPotentialWinning)} naira`;

    const body = `Hey, I just booked a ticket on ${
        successValue?.Game?.name
    } lottery on Gaim, my booking code is ${successValue?.bookingCode} with Ticket ID: ${
        successValue?.ticketId
    } and I am going to win ${currencyFormater(totalPotentialWinning)} naira`;

    return (
        <Modal
            style={{
                minHeight: '80vh',
            }}
            backdrop={'static'}
            keyboard={false}
            open={open}
            onClose={handleClose}
            size="md"
        >
            <Modal.Body>
                <div>
                    <section className={styles.congratulationSection}>
                        <div>
                            <FaThumbsUp className={styles.thumbsUp} />
                        </div>
                        <h5 className={styles.ticketSuccessText}>Ticket Booked Successfully</h5>
                        {successValue?.bookingCode ? (
                            <h6 className={styles.ticketSuccessText}>
                                Booking Code: {successValue?.bookingCode}
                            </h6>
                        ) : null}
                    </section>
                    <br />
                    <section className={styles.buttonsDiv}>
                        {/* <ReactToPrint
                        content={reactToPrintContent}
                        documentTitle="Ticket Slip"
                        trigger={reactToPrintTrigger}
                    /> */}

                        <Button
                            appearance="primary"
                            color="blue"
                            className={styles.button}
                            onClick={() => {
                                dispatch(handleResetBetSlips());
                                handleClose();
                            }}
                        >
                            Close
                        </Button>
                    </section>
                    <br />
                    <ul className={styles.socialUl}>
                        <li className={styles.socialLi}>
                            <FacebookShareButton url={renderHostURL()} hashtag="#whitelabel">
                                <FacebookIcon size={'2.2rem'} />
                            </FacebookShareButton>
                        </li>
                        <li className={styles.socialLi}>
                            <LinkedinShareButton
                                url={renderHostURL()}
                                title={title}
                                summary={body}
                                source={`${window.location.host}`}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <LinkedinIcon size={'2.2rem'} round />
                            </LinkedinShareButton>
                        </li>
                        <li className={styles.socialLi}>
                            <WhatsappShareButton
                                url={renderHostURL()}
                                title={title}
                                separator=":: "
                            >
                                <WhatsappIcon size={'2.2rem'} round />
                            </WhatsappShareButton>
                        </li>
                        <li className={styles.socialLi}>
                            <TwitterShareButton
                                url={renderHostURL()}
                                title={title}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <TwitterIcon size={'2.2rem'} round />
                            </TwitterShareButton>
                        </li>
                        <li className={styles.socialLi}>
                            <TelegramShareButton title={title} url={`${window.location.host}`}>
                                <TelegramIcon size={'2.2rem'} round />
                            </TelegramShareButton>
                        </li>
                        <li className={styles.socialLi}>
                            <PinterestShareButton
                                url={`${window.location.host}`}
                                media="/images/logo1.jpg"
                                description={body}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <PinterestIcon size={'2.2rem'} round />
                            </PinterestShareButton>
                        </li>
                        <li className={styles.socialLi}>
                            <EmailShareButton
                                url={renderHostURL()}
                                subject={title}
                                body={body}
                                windowWidth={750}
                                windowHeight={600}
                            >
                                <EmailIcon size={'2.2rem'} round />
                            </EmailShareButton>
                        </li>
                    </ul>
                    {/* <div className={styles.printComponentWrapper}>
                    <ComponentToPrint
                        ref={componentRef}
                        successValue={successValue}
                        QRImage={QRImage}
                    />
                </div> */}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default BookedTicketSuccessModal;
