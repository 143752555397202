import React from 'react';
import ReactToPrint from 'react-to-print';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    TelegramShareButton,
    PinterestShareButton,
    EmailShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    TelegramIcon,
    PinterestIcon,
    EmailIcon,
} from 'react-share';
import styles from './ComponentToPrint.module.css';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import QRCode from 'qrcode';
import { FaThumbsUp } from 'react-icons/fa';
import Image from 'next/image';
import { handleResetBetSlips } from 'redux/actions/lotteryActions';
import { currencyFormater, FIVE_0F_NINETY, LEGENDARY_LOTTO, safeJSONParse } from 'utils';
import { handleSetWalletInfo } from 'redux/actions/userActions';

export const ComponentToPrint = React.forwardRef((props, ref) => {
    const { QRImage, successValue, printCount } = props;
    const { userData } = useSelector((state) => state?.userData);

    let betSlips = [],
        raffle = [];
    if (successValue && typeof successValue?.betSlips === 'string') {
        betSlips = safeJSONParse(successValue?.betSlips);
    } else {
        betSlips = successValue?.betSlips;
    }
    if (successValue && typeof successValue?.raffle === 'string') {
        raffle = safeJSONParse(successValue?.raffle);
    } else {
        raffle = successValue?.raffle;
    }
    return (
        <div ref={ref} className={styles.printContainerStyle}>
            <p className={styles.rotingtxt}>{printCount >= 1 ? 'Duplicate' : 'Original'}</p>
            <Image
                src={'/images/logo1.jpg'}
                alt="OurLotto lotto"
                width={90}
                height={40}
                className={styles.printContainerImage}
            />
            {betSlips?.length > 0 &&
                betSlips?.map((item, idx) => (
                    <div key={`${idx}nn`} className={styles.betSlipsTableBodyRow}>
                        <h6 className={styles.tableBodyCell}>
                            {item?.selections ? `${item?.selections}/` : null}
                            {item?.overUnder ? `${item?.overUnder}/` : null}
                            {successValue?.linesCount ? `${successValue?.linesCount}/` : null}
                            {currencyFormater(item?.amount)}/
                            {currencyFormater(item?.potentialWinning)}
                        </h6>
                        <h6 className={styles.tableBodyCell}>
                            {item?.betType ? `${item?.betType}` : null}{' '}
                            {(successValue?.Game?.Lottery?.category === FIVE_0F_NINETY ||
                                successValue?.Game?.Lottery?.category === LEGENDARY_LOTTO) &&
                            item?.resultType
                                ? `/${item?.resultType}`
                                : null}
                            {(successValue?.Game?.Lottery?.category === FIVE_0F_NINETY ||
                                successValue?.Game?.Lottery?.category === LEGENDARY_LOTTO) &&
                            item?.booster
                                ? `/${item?.booster}`
                                : null}
                        </h6>

                        <p>------------------------------------------------------------------</p>
                    </div>
                ))}
            <section
                className={styles.printBottomDiv}
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}
            >
                <div>
                    <h6 className={styles.tableBodyCell}>Game: {successValue?.Game?.name}</h6>
                    {printCount >= 1 ? null : (
                        <h6 className={styles.tableBodyCell}>
                            {successValue?.redemptionCode
                                ? `Redemption Code: ${successValue?.redemptionCode}`
                                : null}
                        </h6>
                    )}
                    {raffle ? (
                        <h6 className={styles.tableBodyCell}>
                            Raffle:{' '}
                            {raffle?.map((item, index) => (
                                <span key={item}>
                                    {typeof item === 'object' ? <> {item?.code}</> : <> {item} </>}
                                    {index === raffle?.length - 1 ? ' . ' : ', '}
                                </span>
                            ))}
                        </h6>
                    ) : null}
                    <h6 className={styles.tableBodyCell}>
                        Total Staked Amount: {currencyFormater(successValue?.totalStakedAmount)}
                    </h6>
                    <h6 className={styles.tableBodyCell}>Ticket ID: {successValue?.ticketId}</h6>
                    <h6 className={styles.tableBodyCell}>
                        Terminal ID: {successValue?.ticketId?.substring(0, 6)}
                    </h6>
                    <h6 className={styles.tableBodyCell}>Agent ID: {userData?.userId}</h6>
                    <h6 className={styles.tableBodyCell}>
                        Date: {dayjs().format('DD/MM/YYYY, HH:mm')}
                    </h6>
                </div>{' '}
                {QRImage && (
                    <div style={{ width: '76px', height: '76px' }}>
                        <Image
                            unoptimized
                            alt="ticket qrcode"
                            src={QRImage}
                            width={76}
                            height={76}
                        />
                    </div>
                )}
            </section>
        </div>
    );
});

const TicketSuccessModal = ({ handleClose, successValue }) => {
    const componentRef = React.useRef(null);
    const dispatch = useDispatch();
    const { userData } = useSelector((state) => state?.userData);
    const onBeforeGetContentResolve = React.useRef(null);
    const [QRImage, setQRImage] = React.useState(null);
    const [printCount, setPrintCount] = React.useState(0);

    let totalPotentialWinning = 0;
    let maxTotalPotentialWinning = 0;
    let totalSelections = '';
    if (successValue && typeof successValue?.betSlips === 'string') {
        safeJSONParse(successValue?.betSlips)?.forEach((item) => {
            if (item?.selections) {
                totalSelections = totalSelections + ', ' + item?.selections;
            }
            totalPotentialWinning += Number(item?.potentialWinning?.min);
        });

        safeJSONParse(successValue?.betSlips)?.forEach((item) => {
            maxTotalPotentialWinning += Number(item?.potentialWinning?.max);
        });
    } else {
        successValue?.betSlips?.forEach((item) => {
            if (item?.selections) {
                totalSelections = totalSelections + ', ' + item?.selections;
            }
            totalPotentialWinning += Number(item?.potentialWinning?.min);
        });

        successValue?.betSlips?.forEach((item) => {
            maxTotalPotentialWinning += Number(item?.potentialWinning?.max);
        });
    }
    const title = `Hey, I just played ${
        successValue?.Game?.name
    } lottery on OurLotto Lotto, My Ticket ID is ${
        successValue?.ticketId
    } and I am going have a minimum potential winning of ${currencyFormater(
        totalPotentialWinning,
    )} naira and a maximumn potential winning of ${currencyFormater(totalPotentialWinning)}`;
    const body = `Hey, I just played ${
        successValue?.Game?.name
    } lottery on OurLotto Lotto, My Ticket ID is ${
        successValue?.ticketId
    } and I am going to win ${currencyFormater(
        totalPotentialWinning,
    )} naira, chekout my selections ${totalSelections}`;

    const generateQR = async (text) => {
        try {
            const genCode = await QRCode.toDataURL(text);
            setQRImage(genCode);
        } catch (err) {
            console.error(err);
        }
    };

    React.useEffect(() => {
        generateQR(successValue?.ticketId);
    }, []);

    React.useEffect(() => {
        if (typeof onBeforeGetContentResolve.current === 'function') {
            onBeforeGetContentResolve.current();
        }
    }, [onBeforeGetContentResolve.current]);

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handleAfterPrint = React.useCallback(() => {
        setPrintCount((prevState) => prevState + 1);
    }, []);

    const reactToPrintTrigger = React.useCallback(() => {
        return (
            <Button appearance="primary" color="green" className={styles.button}>
                &nbsp; Print Slip &nbsp;
            </Button>
        );
    }, []);

    return (
        <Modal.Body>
            <div>
                <section className={styles.congratulationSection}>
                    <div>
                        <FaThumbsUp className={styles.thumbsUp} />
                    </div>
                    <h5 className={styles.ticketSuccessText}>Ticket Submited Successfully</h5>
                    {successValue?.ticketId ? (
                        <h6 className={styles.ticketSuccessText}>
                            Ticket ID: {successValue?.ticketId}
                        </h6>
                    ) : null}
                </section>
                <br />
                <section className={styles.buttonsDiv}>
                    {userData?.role === 'cashier' ? (
                        <ReactToPrint
                            content={reactToPrintContent}
                            documentTitle={`ticket slip ${new Date().getTime()}`}
                            trigger={reactToPrintTrigger}
                            onAfterPrint={handleAfterPrint}
                        />
                    ) : null}

                    <Button
                        appearance="primary"
                        color="blue"
                        className={styles.button}
                        onClick={async () => {
                            await dispatch(handleSetWalletInfo());
                            dispatch(handleResetBetSlips());
                            handleClose();
                        }}
                    >
                        Close
                    </Button>
                </section>
                <br />
                <ul className={styles.socialUl}>
                    <li className={styles.socialLi}>
                        <FacebookShareButton
                            url={typeof window === 'object' && window.location.host}
                            hashtag="#OurLotto_lotto"
                        >
                            <FacebookIcon size={'2.2rem'} />
                        </FacebookShareButton>
                    </li>
                    <li className={styles.socialLi}>
                        <LinkedinShareButton
                            url={typeof window === 'object' && window.location.host}
                            title={title}
                            summary={body}
                            source={`${window.location.host}`}
                            windowWidth={750}
                            windowHeight={600}
                        >
                            <LinkedinIcon size={'2.2rem'} round />
                        </LinkedinShareButton>
                    </li>
                    <li className={styles.socialLi}>
                        <WhatsappShareButton
                            url={typeof window === 'object' && window.location.host}
                            title={title}
                            separator=":: "
                        >
                            <WhatsappIcon size={'2.2rem'} round />
                        </WhatsappShareButton>
                    </li>
                    <li className={styles.socialLi}>
                        <TwitterShareButton
                            url={typeof window === 'object' && window.location.host}
                            title={title}
                            windowWidth={750}
                            windowHeight={600}
                        >
                            <TwitterIcon size={'2.2rem'} round />
                        </TwitterShareButton>
                    </li>
                    <li className={styles.socialLi}>
                        <TelegramShareButton title={title} url={`${window.location.host}`}>
                            <TelegramIcon size={'2.2rem'} round />
                        </TelegramShareButton>
                    </li>
                    <li className={styles.socialLi}>
                        <PinterestShareButton
                            url={`${window.location.host}`}
                            media="/images/logo1.jpg"
                            description={body}
                            windowWidth={750}
                            windowHeight={600}
                        >
                            <PinterestIcon size={'2.2rem'} round />
                        </PinterestShareButton>
                    </li>
                    <li className={styles.socialLi}>
                        <EmailShareButton
                            url={typeof window === 'object' && window.location.host}
                            subject={title}
                            body={body}
                            windowWidth={750}
                            windowHeight={600}
                        >
                            <EmailIcon size={'2.2rem'} round />
                        </EmailShareButton>
                    </li>
                </ul>
                <div className={styles.printComponentWrapper}>
                    <ComponentToPrint
                        ref={componentRef}
                        successValue={successValue}
                        QRImage={QRImage}
                        printCount={printCount}
                    />
                </div>
            </div>
        </Modal.Body>
    );
};

export default TicketSuccessModal;
