import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import styles from './GallerySlider.module.css';
import BtnSlider from './BtnSlider';
import { filterBanners, getUserToken } from 'utils';
import FullScreenError from 'components/shared/FullScreenError/FullScreenError';
import { Button } from 'rsuite';

export default function GallerySlider() {
    const limit = 20;
    const [slideIndex, setSlideIndex] = useState(1);
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/banner/fetch-banners?limit=${limit}&category=slider`;

    // const moveDot = (index) => {
    //     setSlideIndex(index);
    // };
    const loopSlide = useCallback(() => {
        return setInterval(nextSlide, 7500);
    }, [slideIndex]);

    useEffect(() => {
        const interval = loopSlide();

        return () => {
            return clearInterval(interval);
        };
    }, [slideIndex, loopSlide]);
    const fetcher = async () => {
        const token = getUserToken();
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        let result = [];
        result = filterBanners(res.data?.data?.data, 'slider');

        return result;
    };

    const { data, error } = useSWR(url, fetcher);
    if (error) {
        console.log(error);
        return null;
    }

    if (!data) {
        return null;
    }

    const nextSlide = () => {
        if (slideIndex !== data?.length) {
            setSlideIndex(slideIndex + 1);
        } else if (slideIndex === data?.length) {
            setSlideIndex(1);
        }
    };

    const prevSlide = () => {
        if (slideIndex !== 1) {
            setSlideIndex(slideIndex - 1);
        } else if (slideIndex === 1) {
            setSlideIndex(data?.length);
        }
    };
    return (
        <>
            {data?.length > 0 ? (
                <section className={styles.bannerContainer}>
                    <div className={styles.containerSlider}>
                        {data.map((obj, index) => {
                            return (
                                <div
                                    key={obj.bannerId}
                                    style={{
                                        background: `linear-gradient(rgba(255, 255, 255, 0.2), rgba(255,255,0,0.2)), url(${obj.imageUrl})`,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        padding: '5px',
                                    }}
                                    className={
                                        slideIndex === index + 1
                                            ? `${styles.slide} ${styles.activeAnim}`
                                            : styles.slide
                                    }
                                >
                                    {/* <section className={styles.galleryContentSection}>
                                <h3 className={styles.bannerTitle}>{obj?.title}</h3>
                                <br />
                                <p className={styles.bannerDesc}>{obj?.content}</p>
                            </section> */}
                                </div>
                            );
                        })}
                        <BtnSlider moveSlide={nextSlide} direction={'next'} />
                        <BtnSlider moveSlide={prevSlide} direction={'prev'} />
                    </div>
                    {/* <section className={styles.galleryContentSectionInner}>
                        <h3 className={styles.bannerTitle}>{data[slideIndex - 1]?.title}</h3>
                        <br />
                        <p className={styles.bannerDesc}>{data[slideIndex - 1]?.content}</p>
                        <br />
                        <Button appearance="primary" className={styles.bannerButton}>
                            Continue
                        </Button>
                    </section> */}
                </section>
            ) : null}
        </>
    );
}
