import React, { useMemo, useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import styles from 'public/styles/GameComponents.module.css';
import { useSelector } from 'react-redux';
import { formatGameDate, getActiveGameFromStorage } from 'utils';
import { useRouter } from 'next/router';

const MobileGameTimer = () => {
    const [isCompleted, setIsCompleted] = useState(false);
    const { activeGameInstance } = useSelector((state) => state.lotteryData);
    const router = useRouter();
    const { lotteryId } = router.query;
    const game = getActiveGameFromStorage();
    const runningGame = activeGameInstance || game;
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-game/${runningGame?.gameId}`;

    let gameStartTime = new Date();

    let gameEndTime = useMemo(() => {
        const endDate = new Date();
        let toFormatEndTime = formatGameDate(runningGame?.endTime, true);
        let toFormatStartTime = formatGameDate(runningGame?.startTime, true);
        const startTimeArray = toFormatStartTime?.split(':');
        const endTimeArray = toFormatEndTime?.split(':');

        if (runningGame && runningGame?.endTime) {
            gameStartTime.setHours(Number(startTimeArray[0]));
            endDate.setHours(Number(endTimeArray[0]));

            gameStartTime.setMinutes(Number(startTimeArray[1]));
            endDate.setMinutes(Number(endTimeArray[1]));
        }
        return endDate;
    }, [runningGame?.endTime]);
    useEffect(() => {
        console.log('effect');
    }, [isCompleted]);

    const renderer = ({ hours, minutes, seconds }) => {
        return (
            <span
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '5px',
                    padding: '5px 25px',
                }}
            >
                {hours}:{minutes}:{seconds}
            </span>
        );
    };

    let currentHour = new Date().getHours();

    if (!runningGame?.endTime) {
        return null;
    }

    if (currentHour < gameStartTime.getHours()) {
        return (
            <div
                style={{
                    color: 'white',
                    backgroundColor: 'green',
                    borderRadius: '5px',
                    padding: '5px 5px',
                }}
            >
                Upcomming
            </div>
        );
    }
    if (currentHour > Number(gameEndTime.getHours())) {
        return (
            <div
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '5px',
                    padding: '5px 5px',
                }}
                className={styles.expiredGame}
            >
                Game Time Elapsed
            </div>
        );
    }
    // console.log('sssssssssssssssssssssssss', isCompleted);
    return (
        <>
            {runningGame?.gameId ? (
                <Countdown
                    intervalDelay={0}
                    precision={3}
                    date={gameEndTime}
                    renderer={renderer}
                    onTick={(arg) => {
                        return setIsCompleted(arg.completed);
                    }}
                    onComplete={() => {
                        // console.log('aaaaaaaaaaaaaaaaaaaaaaaa');
                    }}
                />
            ) : null}
        </>
    );
};

export default MobileGameTimer;

export const MobilePOFGameTimer = () => {
    const { activeGameInstance } = useSelector((state) => state.lotteryData);

    const game = getActiveGameFromStorage();
    const runningGame = activeGameInstance || game;
    // console.log('aaaaaaaaaaaaaaaaaaaaaaaa', runningGame);
    let gameStartTime = new Date();
    let gameEndTime = new Date();

    const startTimeArray = runningGame?.startTime?.split(':');
    const endTimeArray = runningGame?.endTime?.split(':');
    if (runningGame && runningGame?.endTime) {
        gameStartTime.setHours(Number(startTimeArray[0]));
        gameEndTime.setHours(Number(endTimeArray[0]));
        gameStartTime.setMinutes(Number(startTimeArray[1]));
        gameEndTime.setMinutes(Number(endTimeArray[1]));
        gameStartTime.setSeconds(Number(startTimeArray[2]));
        gameEndTime.setSeconds(Number(endTimeArray[2]));
    }

    const renderer = ({ days, hours, minutes, seconds }) => {
        return (
            <span
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '5px',
                    padding: '5px 25px',
                }}
            >
                {days}:{hours}:{minutes}:{seconds}
            </span>
        );
    };

    if (Number(runningGame?.currentPoolAmount >= Number(runningGame?.totalFundPool))) {
        return (
            <div
                style={{
                    color: 'white',
                    backgroundColor: 'red',
                    borderRadius: '5px',
                    padding: '5px 5px',
                }}
            >
                Result Realesed
            </div>
        );
    }

    if (
        !runningGame?.alternateStartDate &&
        Number(runningGame?.currentPoolAmount < Number(runningGame?.totalFundPool))
    ) {
        return (
            <div
                style={{
                    color: 'white',
                    backgroundColor: 'green',
                    borderRadius: '5px',
                    padding: '5px 17px',
                }}
            >
                Running
            </div>
        );
    }

    if (!runningGame?.alternateStartDate) {
        return null;
    }

    return (
        <>
            {runningGame?.gameId ? (
                <Countdown
                    intervalDelay={0}
                    precision={3}
                    date={new Date(runningGame?.alternateStartDate)}
                    renderer={renderer}
                />
            ) : null}
        </>
    );
};
